.user-table {
  flex: 1;
  display: table;
  overflow: hidden;
  animation: showAnimation 1s forwards;
  min-height: 624px;

  &>.header {
    background: #F9FAFB;
    height: 44px;
    align-items: center;
    align-self: stretch;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #667085;
    flex-direction: row;

    &>.username {
      margin-left: 24px;
      flex: 1;
    }

    &>.status {
      width: 126px;
      flex-direction: row;
      justify-content: center;

      &>.icon {
        width: 16px;
        height: 16px;
        margin-left: 4px;
        margin-right: 4px;
        &.flip {
          transform: scaleY(-1);
        }
      }
    }

    &>.last {
      width: 72px;
    }
  }

  &>.body {
    flex-direction: column;
  }

  &>.footer {
    height: 68px;
    justify-content: center;
    align-items: center;
  }
}