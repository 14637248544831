.wrapper {
  width: 475px;
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 2px 4px -2px rgba(16, 24, 40, 0.06);
  border: solid 1px #eaecf0;
  background-color: #fff;
  box-sizing: border-box;
}

.logoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.logo {
  width: auto;
  height: 48px;
}

.title {
  font-size: 30px;
  font-weight: 600;
  line-height: 1.27;
  letter-spacing: normal;
  text-align: center;
  color: #101828;
  align-self: center;
  margin-top: 24px;
}

.subtitle {
  font-size: 16px;
  line-height: 1.5;
  color: #667085;
  align-self: center;
  margin-top: 12px;
  text-align: center;
  width: 95%;
}

.form {
  margin-top: 32px;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-bottom: 24px;
  label {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #344054;
  }

  input {
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    padding: 10px 14px;
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    border: solid 1px #d0d5dd;
    background-color: #fff;
  }
}

.forgetPass {
  margin-top: 4px;
  margin-bottom: 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: #40ad9f;
  &:hover{
    cursor: pointer;
  }
}

.error{
  color: red;
  font-size: 12px;
  margin-top: 8px;
}

.competedContainer{
  justify-content: center;
  flex-direction: column;
  margin-bottom: 24px;
  gap: 24px;
  >div{
    align-self: center;
    justify-content: center;
    &.link{
      color: #40ad9f;
      &:hover{
        cursor: pointer;
      }
    }
  }
}
