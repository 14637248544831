.user-delete {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.03), 0 20px 24px -4px rgba(16, 24, 40, 0.08);
  background-color: #fff;

  &>.title {
    font-size: 30px;
    font-weight: 600;
    line-height: 1.27;
    color: #101828;
    margin-bottom: 32px;
  }

  &>.question {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #667085;
  }
  &>.email {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #101828;
  }

  &>.buttons_container {
    gap: 12px;
    align-self: center;
    margin-top: 32px;

    &>.button {
      width: 152px;
    }
  }
  &>.error{
    color: red;
    font-size: 12px;
    margin-top: 8px;
    align-self: flex-start;
  }
}