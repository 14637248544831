.cards{
  flex: 1;
  justify-content: space-between;
  height: fit-content;
  flex-direction: row;
  &.margin{
    margin-top: 24px;
  }
}

.outer-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-container {
  display: flex;
  gap: 5px;
  background-color: #ffffff;
  border-radius: 20px;
  padding: 10px;
}

.button-container button {
  padding: 6px 12px;
  font-size: 14px;
  border: none;
  background-color: #e0e0e0;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button-container button:hover {
  background-color: #d0d0d0;
}