.loader-container {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  animation: showAnimation 1s linear forwards;

  >img {
    width: 50px;
    height: 50px;
    animation: spinAnimation 2s linear infinite;
  }
}
