.account {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 280px;
  height: 40px;
  align-items: center;

  &>.textContainer{
    display: flex;
    flex-direction: column;
    &>.name {
      font-family: 'roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #FFFFFF;
    }
  
    &>.email {
      font-family: 'roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #DADADA;
    }
  }

  &>.imageContainer {
    display: flex;
    flex-direction: row-reverse;
    flex: 1;
    :hover{
      cursor: pointer;
    }

      &>.image {
        margin-right: 15px;
        min-width: 18px;
        min-height: 18px;
        align-self: center;
        max-width: 100%;
        max-height: 100%;
      }
    }

}