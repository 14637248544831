.users-table {
  display: table;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  background-color: #fff;
  border: 1px solid #EAECF0;
  border-radius: 8px;
  width: 1040px;//ali.m workaround
  min-height: 624px;

  &>.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 24px 19px;

    &>.text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 0px;
      gap: 4px;
      flex: 1;

      &>.title {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        color: #101828;
      }

      &>.count {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        color: #667085;
      }
    }

    &>.right-container {
      display: flex;
      flex-direction: row;
      gap: 16px;
      align-items: center;
    }
  }

  &>.body {
    flex: 1 1;
    align-self: stretch;
  }
}