.progress-bar {
  position: relative;
  justify-content: center;
  align-items: flex-end;
  padding: 0px;
  gap: 12px;
  border-radius: 4px;

  &>.inner {
    position: absolute;
    height: 100%;
    left: 0;
    transition: width 1s;
  }
}