.router-container {
  display: flex;
  flex: 1;
}

.login-router {
  background-color: #e9f7f5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}
