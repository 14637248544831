.no-shadow-no-animation {
  box-shadow: none !important;
  transition: none !important;
}

.m-0 {
  margin: 0px !important;
}
.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 338px;
  height: 205px;
  border-radius: 8px;
  justify-content: flex-start;
  align-items: flex-start;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  transition: background-color 2s;

  &.blue {
    background: #58a6d2;
  }

  &.red {
    background: rgb(202, 69, 115);
  }

  &.green {
    background: #40ad9f;
  }

  &.purple {
    background: #a07bc7;
  }

  &.white {
    background: #fff;
  }

  &.noGap {
    gap: 0;
  }

  & > .title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    padding-left: 24px;
    padding-top: 12px;
  }

  & > .numbers {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 20px;
    color: #d3e7f3;
    padding-left: 24px;
  }

  & > .progress {
    align-self: center;
  }

  & > .charts {
    flex-direction: column;
    padding: 0px;
    gap: 10px;
    width: 290px;
    align-self: center;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: #ffffff;

    & > .row {
      flex: 1;
      align-items: center;
    }

    * > .percentage {
      flex: 1;
      justify-content: flex-end;
    }

    * > img {
      width: 20px;
      height: 20px;
      margin-right: 12px;
    }

    * > .fixed-label {
      margin-left: 10px;
      width: 84px;
    }
  }

  &.profile {
    padding: 24px;
    height: auto;
    .title {
      padding: unset;
      margin: unset;
      color: #667085;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      margin-bottom: 4px;

      &.extraTopMargin {
        margin-top: 16px;
      }
    }

    .desc {
      padding: unset;
      margin: unset;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      text-align: left;
      color: #101828;
    }

    &.plan {
      justify-content: space-between;
    }

    .planDetails {
      font-size: 18px;
      font-weight: 500;
      line-height: 1.56;
      color: #101828;
    }

    .expire {
      font-size: 14px;
      line-height: 1.43;
      color: #667085;
    }

    .row {
      display: flex;
      flex-direction: row;
    }

    .colum {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .icon {
      width: 46px;
      margin-right: 20px;
    }

    .count {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.43;
      color: #101828;
      margin-bottom: 8px;
    }
  }
}
