.table-record {
  // height: 72px;
  align-items: center;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #667085;
  border-bottom: 1px solid #eaecf0;
  padding: 16px 24px;

  &.firsttime {
    animation: user-table-animate1 0.5s forwards;
  }

  &.secondtime {
    & > .email {
      animation: user-table-animate2 0.5s forwards;
    }

    & > .status {
      animation: user-table-animate2 0.5s forwards;
    }
  }

  & > .email {
    flex: 1;
    flex-direction: column;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: #667085;
  }

  .name {
    color: #101828;
  }

  & > .status {
    width: 126px;
    user-select: none;

    & > .text {
      // margin-left: 24px;
      padding: 2px 8px;
      border-radius: 16px;
      text-align: left;
    }

    & > .pending {
      background-color: #f3eef8;
      color: #885aba;
    }

    & > .registered {
      background-color: #e9f7f5;
      color: #40ad9f;
    }
  }

  & > .delete {
    justify-content: center;
    user-select: none;
    margin-left: 28px;
    &:hover{
      cursor: pointer;
    }
    > img {
      width: 20px;
      height: 20px;
    }
  }
  &>.disabled {
    opacity: 0.6;

    &:hover {
      cursor: default;
    }
  }
}

@keyframes user-table-animate1 {
  from {
    height: 0px;
  }

  to {
    height: 72px;
  }
}

@keyframes user-table-animate2 {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}
