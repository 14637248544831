.table-record {
  height: 72px;
  align-items: center;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #667085;
  border-bottom: 1px solid #EAECF0;

  .ml-0 {
    margin-left: 0px !important;
   }
  &.firsttime {
    animation: user-table-animate1 0.5s forwards;
  }

  &.secondtime {
    &>.email {
      animation: user-table-animate2 0.5s forwards;
    }
  
    &>.status {
      animation: user-table-animate2 0.5s forwards;
    }
  }

  &>.email {
    margin-left: 24px;
    width: 126px;
    flex: 1;
  }

  &>.status {
    width: 126px;
    user-select: none;

    &>.text {
      margin-left: 24px;
      padding: 2px 8px;
      border-radius: 16px;
      text-align: left;
    }

    &>.pending {
      background-color: #f3eef8;
      color: #885aba;
    }

    &>.expired {
      background-color: #f3eef8;
      color: red;
    }

    &>.registered {
      background-color: #e9f7f5;
      color: #40ad9f;
    }
  }

  &>.delete {
    width: 72px;
    justify-content: center;
    user-select: none;
      &.disabled {
        opacity: 0.6;
        &:hover {
          cursor: default;
        }
      }
      >img {
        width: 20px;
        height: 20px;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }

@keyframes user-table-animate1 {
  from {
    height: 0px;
  }

  to {
    height: 72px;
  }
}

@keyframes user-table-animate2 {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}