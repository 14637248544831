.container {
  width: 538px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 32px;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.03),
    0 20px 24px -4px rgba(16, 24, 40, 0.08);
  background-color: #fff;
  position: relative;

  & > .header {
    flex-direction: column;
    gap: 8px;

    & > .title {
      font-size: 24px;
      font-weight: 500;
      line-height: 1.17;
      text-align: left;
      color: #101828;
    }

    & > .title-supporting {
      font-size: 14;
      line-height: 1.17;
      line-height: 1.43;
      text-align: left;
      color: #667085;
    }
  }

  &.error{
    border: 1px;
    border-color: red;
  }

  & .body {
    flex-direction: column;
    gap: 10px;

    & > .title {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.43;
      text-align: left;
      color: #344054;
      margin-bottom: 6px;
    }

    .inputContainer {
      flex-direction: row;
      position: relative;
      gap: 24px;
    }

    .flex1 {
      flex: 1;
    }

    .flex2 {
      flex: 2;
    }

    & > .email-input {
      height: 44px;
      background: #ffffff;
      border: 1px solid #eaecf0;
      border-radius: 8px;
      padding-left: 14px;
      gap: 8px;
      align-items: center;

      img {
        width: 20px;
        height: 20px;
      }

      input {
        border: none;
        flex: 1;
      }

      select {
        border: none;
        flex: 1;
      }

      ::placeholder {
        font-size: 16px;
        line-height: 1.5;
        text-align: left;
        color: #667085;
      }
    }

    & > .add {
      user-select: none;

      &:hover {
        cursor: pointer;
      }

      > img {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }

      margin-top: 12px;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.43;
      text-align: left;
      span {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.43;
        color: #667085;
      }
    }

    & > .or {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      text-align: center;
      color: #667085;
      align-self: center;
      height: 28px;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    & > .file-input {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 16px 24px;
      gap: 4px;
      background: #ffffff;
      border: 1px dashed #eaecf0;
      border-radius: 8px;

      & > .image-upload {
        width: 40px;
        height: 40px;
        margin-bottom: 12px;
      }

      *.click {
        cursor: pointer;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #40ad9f;
      }

      *.unclick {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin-left: 4px;
        color: #667085;
      }
    }

    & > .buttons_container {
      gap: 12px;
      align-self: flex-end;
      margin-top: 32px;
      & > .button {
        width: 180px;
      }
    }
  }
}

.delete {
  justify-content: center;
  user-select: none;
  right: -30px;
  position:absolute;
  top:50%;
  transform: translateY(-50%);
  > img {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}
