.column-container {
  flex-direction: column;
  position: absolute;
  bottom: 0;
  overflow: visible;

  &>.column {
    transition: all 1s;
    justify-content: space-around;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 5.5rem;
    background: #DCF4E6;
    position: relative;
  }

  >.title {
    position: absolute;
    bottom: -20px;
    left: 25px;
    color: rgba(0, 0, 0, 0.6);
    font-size: 10px;
  }
}

.hide-show {
  animation-name: show;
  animation-duration: 1s;
}

.mt-25px {
  margin-top: 25px;
}

.mt-10px {
  margin-top: 10px;
}

.mood-column-icon {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  text-align: center;
  font-size: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: bold;
}

.mood-column-label {
  font-size: 12px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.6);
  margin-left: 8px;
  margin-right: 24px;
}

.mood-bottom {
  position: absolute;
  bottom: -50px;
  left: 50%;
  transform: translateX(-50%);
}

.mood-column {
  z-index: 1;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  text-align: center;
  align-self: center;
  font-size: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: transform .5s;
  position: relative;
  transform: scale(1.2);
  cursor: pointer;
  
  // &:hover {
  //   transform: scale(2);
  //   cursor: pointer;

  //   .mood-tooltip {
  //     opacity: 1;
  //   }
  // }
}

.mood-tooltip {
  opacity: 1 !important;
}

.mood-tooltip {
  transition: all .5s;
  font-size: 12px;
  opacity: 0;
  z-index: 10;
}

.mood-top-container {
  padding-bottom: 50px;

  .colors-bar {
    width: 20px;
    height: 2px;
    background-image: linear-gradient(to right, #36B366 -2%, #58a6d2 49%, #db7393 98%);
    align-self: center;
  }
}

.mood-container {
  background: #fff;
  position: relative;

  &>canvas {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  &>.horizontal-line {
    position: absolute;
    left: 0%;
    width: 100%;
    height: 1px;
    background: #F2F4F7;
  }

  &>.vertical-line {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 100%;
    background: #F2F4F7;
  }

  &>.vertical-label {
    position: absolute;
    color: #667085;
    font-size: 12px;
    left: -5px;
    margin-top: -12px;
    transform: translateX(-100%);
  }
}


@keyframes show {
  0% {
    opacity: 0;
  }

  ;

  50% {
    opacity: 0;
  }

  ;

  100% {
    opacity: 1;
  }

  ;
}