.Tabs {
  position: relative;
  display: inline-block;
  margin-bottom: 3px;
  padding-left: 32px;
  border-bottom: solid 1px #eaecf0;
}

.Tabs__Tab {
  display: inline-block;
  font-size: 14px;
  padding: 12px;
  margin-left: 16px;
  color: #667085;
  cursor: pointer;
  line-height: 1.43;
}

.Tabs__Tab:hover,
.Tabs__Tab--active {
  color: #40ad9f;
}

.Tabs__Underline {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  border-bottom: 2px solid #40ad9f;
}
