.search-users {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 14px;
  gap: 8px;

  width: 261px;
  height: 40px;

  background: #FFFFFF;
  border: 1px solid #EAECF0;
  border-radius: 8px;

  &>.image {
    width: 20px;
    height: 20px;
  }

  &>.search {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #667085;
    border: none;
    flex: 1;
  }
}
