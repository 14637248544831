.contact-us {
  width: 624px;
  background: #FFFFFF;
  border: 1px solid #EAECF0;
  border-radius: 8px;
  flex-direction: column;
  padding: 24px;
  align-self: center;

  &>.title {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #101828;
  }

  &>.sub-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #667085;
    margin-top: 4px;
  }

  &>.line {
    justify-content: stretch;
    height: 1px;
    background: #EAECF0;
    margin-top: 20px;
    margin-bottom: 25px;
  }

  &>.contact-us-form {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &>.section-container {
      flex-direction: row;
      gap: 32px;
      font-weight: 500;
      font-size: 14px;
      color: #344054;
    }

    *>.section {
      flex: 1;
      flex-direction: column;
      gap: 6px;
    }
  }

  input, textarea, select {
    border: 1px solid #EAECF0;
    border-radius: 8px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
    flex: 1;
    resize: none;
  }

    select {
      font-size: 16px;
      line-height: 1.5;
      text-align: left;
      color: #344054;
    }

    &.extraMargin {
      margin-top: 32px;
    }

    .notficiation-success {
      font-size: 12px;
      text-align: left;
      color: rgb(7, 137, 75);
    }
}