.page-body {
  background-color: #fcfcfd;
  flex: 1;
  overflow: auto;
  flex-direction: column;
  animation: showAnimation 0.5s forwards;
  opacity: 0;

  &>.inner-body {
    width: 1064px;
    max-width: 1064px;
    flex-direction: column;
    align-self:center;
  }
  
}

.export-btn {
  max-width: 132px;
  cursor: pointer;
}


.justify-title {
  width: 88%;
  justify-content: space-between;
}

.export-btn-black {
  filter: invert(37%) sepia(62%) saturate(392%) hue-rotate(100deg) brightness(93%) contrast(86%);
}

.width-98{
  width: 96% !important;
}

.export-btn-none, .chart-options-container-none {
  display: none !important;
}

.padding-25px {
  padding: 25px !important;
}