.modal-container{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.2);
  animation: showAnimation 0.5s forwards;
  justify-content: center;
  align-items: center;
  &.hide{
    animation: hideAnimation 0.5s forwards;
  }
}