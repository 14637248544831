.wrapper {
  display: flex;
}

.card_flex {
  flex: 1;
  &:not(:last-child) {
    margin-right: 24px;
  }
}
