.sidebar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 312px;
  height: 100%;

  background: linear-gradient(180deg, #40AD9F 0%, #885ABA 100%);
  align-items: center;

  animation: showbar 1s forwards;

  >.logo {
    width: 90.4px;
    height: 32px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    background-image: url('../../resources/images/Logo.svg');
    margin-top: 32px;
  }

  &>.topContainer {
    margin-top: 24px;
    flex: 1;
  }

  &>.bottomContainer {
    margin-bottom: 32px;
  }

  &>.splitter {
    width: 90%;
    height: 1px;
    background: rgba(255, 255, 255, 0.16);
    margin-bottom: 24px;
    margin-top: 24px;
  }
}

@keyframes showbar {
  from {
    transform: translateX(-300px);
  }
  to {
    transform: translateX(0);
  }
}