.page-header {
  height: 100px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 32px;
  justify-content: center;
  background: #FFFFFF;
  border-bottom: solid 2px #eaecf0;
  animation: showAnimation 0.5s forwards;
  opacity: 0;

  &.borderless{
    border: none
  }

  &>.text {
    background-color: var(--general-white);
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: normal;
    text-align: left;
    line-height: 38px;
    color: #101828;
  }

  &>.text-small {
    font-size: 16px;
    line-height: 1.5;
    color: #667085;
    margin-top: 4px;
  }

  &>.text-red {
    font-size: 16px;
    line-height: 1.5;
    color: red;
    margin-top: 4px;
  }
}

.button {
  padding: 6px 12px;
  font-size: 16px;
  margin-right: 4px;
  border: none;
  background-color: #e0e0e0;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.header-centered {
  width: 100%;
  justify-content : space-between;
  align-self:flex-start;
  padding-left: 0px;
}

.align-end {
  align-items: end;
}