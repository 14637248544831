.chart {
  flex-direction: column;
  width: 520px;
  min-height: 372px;
  background-color: #EAECF0;
  border: 1px solid #EAECF0;
  box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  border-radius: 8px;
  overflow: hidden;

  .d-flex-start{ 
    display: flex;
    justify-content: start;
    margin-left: 4rem;
    width: 100%;
  }

  .daily-unit-label {
    padding: 0px;
    margin: 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-anchor: middle;
    color: #667085;
    transform: translateX(15px);
  }

  .pt-1 {
    padding-top: 5px !important;
  }

  .m-auto {
    margin: auto;
  }

  &.fit {
    width: 100%;
  }

  &>.header {
    flex-direction: column;
    align-items: flex-start;
    background: #FFFFFF;
    border-bottom: 1px solid #EAECF0;
    justify-content: center;
    padding-left: 24px;
    padding-top: 20px;
    padding-bottom: 20px;

    &>.title {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #101828;
      align-items: center;
    }

    &>.days {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #667085;
      margin-top: 4px;
    }
  }

  &>.body {
    background: #FFFFFF;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    padding-top: 24px;
    padding-bottom: 24px;
    flex: 1;

    &>.item {
      flex-direction: row;
      padding-left: 24px;
      padding-right: 24px;
      align-items: center;

      &>.title {
        width: 150px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #101828;
      }

      &>.number {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #101828;
        flex: 1;
        justify-content: end;
      }
    }
  }
}

.chart-svg-container {
  margin-left: 70px;
  margin-top: 10px;
  animation: showAnimation 1s linear forwards;
}

.svg-label-x {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-anchor: middle;
  fill: #667085;
  &.bold{
    font-weight: 500;
  }
}

.svg-label-y {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-anchor: middle;
  fill: #667085;
  transform: translateX(-20px);
}

.block {
  display: block;
}

.mb-1{
  margin-bottom: 1rem;
}

.w-100 {
  width: 100%;
}