.resources-container { 
  flex: 1;
  display: flex;
  flex-direction: column;

  &>.page-body>.inner-body {
    align-self: flex-start;
  }
}
.resources {
  flex-direction: column;
  gap: 24px;
  *>.line, &>.line {
    height: 1px;
    align-self: stretch;
    flex-grow: 0;
    background-color: #eaecf0;
  }
}
.resources-category {
  flex-direction: column;
  &>.title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #101828;
    margin-bottom: 12px;
  }
  &>.files-container{
    gap: 16px;
    flex-wrap: wrap;
  }
  *>.file-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 16px;
    //width: 344px; ali.m value should be 344
    width:344px;
    height: 244px;
    background: #FFFFFF;
    border: 1px solid #EAECF0;
    border-radius: 8px;
    align-items: center;

    &>.background {
      flex: 1;
      background: #E9F7F5;
      align-self: stretch;
      justify-content: center;
      &>.icon{
        align-self: center;
        width: 36px;
        height: 36px;
      }
    }

    &>.title {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #101828;
    }

    &>.download {
      text-decoration: none;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.43;
      color: #40ad9f;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
