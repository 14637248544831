.container {
  width: 475px;
  display: block;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 32px;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.03),
    0 20px 24px -4px rgba(16, 24, 40, 0.08);
  background-color: #fff;

  .title {
    display: block;
    color: #101828;
    font-size: 30px;
    font-weight: 600;
    line-height: 1.27;
    text-align: center;
    margin-bottom: 32px;
  }

  .body {
    display: flex;
    flex-direction: column;
  }

  .buttonsContainer {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  }

  .label {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: #344054;
    margin-bottom: 6px;
  }

  .inputFiled {
    // gap: 8px;
    padding: 10px 14px;
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    border: solid 1px #d0d5dd;
    background-color: #fff;
  }

  .inputWrapper {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .error {
    color: red;
    font-size: 12px;
  }
}
