.table-navigation {
  >.limits {
    width: 48px;
    height: 36px;
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    justify-content: center;
    flex-direction: column;
    margin-left: 24px;
    margin-right: 24px;
    cursor: pointer;

    &.reverse {
      >img {
        transform: scaleX(-1);
      }
    }

    >img {
      width: 20px;
      height: 20px;
      align-self: center;
    }
  }

  >.number {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    color: #667085;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.selected {
      background: #E9F7F5;
      color: #40AD9F;
    }
  }
}
