.user-onboarding {
  width: 645px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 32px;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.03), 0 20px 24px -4px rgba(16, 24, 40, 0.08);
  background-color: #fff;
  transition: all 0.5s ease-out;

  &>.header {
    flex-direction: column;
    gap: 8px;

    &>.title {
      font-size: 24px;
      font-weight: 500;
      line-height: 1.17;
      text-align: left;
      color: #101828;
    }

    &>.title-supporting {
      font-size: 14;
      line-height: 1.17;
      line-height: 1.43;
      text-align: left;
      color: #667085;
    }
  }

  &>.body .body {
    transition: all 0.5s 0.1s ease-out;
    position: absolute;
    width: 100%;
  }

  &>.body {
    position: relative;
  }

  &>.body, &>.body>.body {
    flex-direction: column;

    &>.title {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.43;
      text-align: left;
      color: #344054;
      margin-bottom: 6px;
    }

    &>.list {
      flex-direction: column;
      overflow-y: scroll;
      list-style: none;
      margin-block: 0;
      padding-inline: 0;
      padding: 0;
      transition: all 0.6s 0.15s ease-out;

      &>.list-container{
        list-style: none;
        position: relative;
        height: 46px;

        &:not(:first-child) {
          margin-top: 10px;
        }

        & .list-item {
          left: 0;
          position: absolute;
          top: 0;
          transition: all 0.6s 0.2s ease-out;
          width: 95%;
          padding: 2px 14px;
          transform: translateY(-300px);

          &.show {
            opacity: 1;
            transform: translateY(0);

            &:not(:first-child) {
              margin-top: 10px;
            }
          }
        }

        &>.email-input {
          background: #FFFFFF;
          border: 1px solid #EAECF0;
          border-radius: 8px;
          gap: 8px;
          align-items: center;

          img {
            width: 20px;
            height: 20px;
          }
    
          input {
            border: none;
            flex: 1;
            padding: 12px 8px 12px 0;
          }

          .icon {
            cursor: pointer;

            &.disabled {
              cursor: default;
              opacity: 0.7;
            }
          }

          &.error {
            border: 1px solid rgba(197, 8, 8, 0.8);
          }

          ::placeholder {
            font-size: 16px;
            line-height: 1.5;
            text-align: left;
            color: #667085;
          }
        }
      }
    }

    &>.add {
      user-select: none;
      position: relative;

      &:hover {
        cursor: pointer;
      }

      &.error {
        &::before {
          content: "Invalid email, please enter a valid email";
          color: rgba(197, 8, 8, 1);
          font-size: 12px;
          line-height: 1.25;
          font-weight: 400;
          position: absolute;
          top: 22px;
          left: 3px;
        }
      }

      >img {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }

      font-size: 14px;
      font-weight: 500;
      line-height: 1.43;
      text-align: left;
      color: #40ad9f;
    }

    &>.or {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      text-align: center;
      color: #667085;
      align-self: center;
      height: 28px;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    &>.file-input {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 16px 24px;
      gap: 4px;
      background: #FFFFFF;
      border: 1px dashed #EAECF0;
      border-radius: 8px;
      position: relative;
      box-shadow: none;
      transition: box-shadow 0.3s 0s ease-in;

      &.highlight {
        box-shadow: 0 0px 0px -10px rgb(229 247 244 / 80%), 0px 0px 10px 3px rgb(216 246 241 / 95%);
      }


      &>.upload-icon-wrapper {
        align-items: center;
        justify-content: center;
        flex-flow: row-reverse nowrap;
        gap: 8px;
        margin-bottom: 12px;

        span {
          max-width: 200px;
          word-wrap: break-word;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          color: #344054;
          line-height: 1.25;

          &+.image-upload {
            width: 30px;
            height: 30px;
          }
        }
        
        & .image-upload {
          width: 40px;
          height: 40px;
          cursor: pointer;
        }
      }

      *.click {
        cursor: pointer;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #40AD9F;
      }

      *.unclick {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin-left: 4px;
        color: #667085;
      }

      input {
        position: absolute;
        width: 100%;
        background: aliceblue;
        height: 100%;
        display: none;
      }

      &.error {
        &::after {
          content: attr(data-error);
          color: rgba(197, 8, 8, 1);
          font-size: 12px;
          line-height: 1.25;
          font-weight: 400;
          position: absolute;
          position: absolute;
          bottom: -22px;
          left: 0px;
        }
      }
    }

    &>.buttons_container {
      gap: 12px;
      align-self: flex-end;
      margin-top: 32px;
      &>.button {
        width: 180px;
      }
      &>.cancel {
        color: #344054;
        background-color: #ffffff;
        box-shadow: 0 1px 2px 1px rgba(16, 24, 40, 0.05);
        border-style: solid;
        border-width: 1px;
        border-color: #d0d5dd;
      }
    }
  }
}