.navigationItem {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 280px;
  height: 40px;
  background-color: rgba($color: #FFF, $alpha: 0.0);
  align-items: center;
  border-radius: 6px;

  &:hover {
    background-color: rgba($color: #FFF, $alpha: 0.08);
  }

  &.selected {
    background-color: rgba($color: #FFF, $alpha: 0.16);
  }


  &>.imageContainer {
    width: 24px;
    height: 24px;
    margin-right: 12px;
    margin-left: 12px;

    &>.image {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &>.text {
    font-family: 'roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
  }

}