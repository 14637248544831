.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 8px;
  height: fit-content;
  background-color: #40AD9F !important;
  user-select: none;
  color: #FFFFFF;
  &:hover{
    cursor: pointer;
    opacity: 0.9;
  }
  &.disabled{
    &:hover{
      cursor: default;
    }
  }

  >.title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 24px;
    margin-right: 24px;
  }
}